<template>
  <div class="bbs-sidebar-box" v-if="subjectList.length">
    <div class="side-posts">
      <div class="side-tit">
        <div class="side-tit-left">{{ $t('bbs.related_osts') }}</div>
        <!-- 相关帖子 -->
      </div>
      <div class="side-posts-list">
        <div
          class="side-posts-item"
          v-for="item in subjectList"
          :key="item.subjectId"
          @click="subjectJump(item)"
        >
          <p>{{ item.title || item.contents }}</p>
        </div>
        <a-empty v-if="!subjectList.length" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { getSubjectList } from "@/api/bbs";
import checkJump from "@/views/bbs/utils/checkJump";
export default {
  name: "sidePosts",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    subjectType: {
      type: Number,
      default: 1,
    },
    topics: {
      type: Array,
      default: [],
    },
    groupId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const state = reactive({
      subjectList: [],
    });

    let subjectParams = {
      status: 1,
      pageSize: 5,
      page: 1,
      subjectType: props.subjectType,
    };
    if (props.topics && props.topics.length) {
      subjectParams.topicID = props.topics[0].topicId;
    } else {
      subjectParams.groupID = props.groupId;
    }

    getSubjectList(subjectParams).then((res) => {
      state.subjectList = (res.data.list || []).slice(0, 5);
    });

    const subjectJump = (item) => {
      checkJump(props.newWindow, item);
    };

    return {
      ...toRefs(state),
      subjectJump,
    };
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-left {
    font-size: 16px;
    color: #202020;
  }
  &-right {
    a {
      font-size: 14px;
      color: #666666;
      transition: color 0.3s;
      &:hover {
        color: @color-theme;
      }
    }
  }
}
.side-posts {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  &-list {
    padding: 15px 20px;
  }
  &-item {
    .mixinEllipsis(24px);
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #666;
      .mixinEllipsis(22px);
      margin: 0;
      transition: color 0.3s;
      cursor: pointer;
      &:hover {
        color: @color-theme;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
